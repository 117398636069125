<template>
    <div>
        <view-request
            :request="requestItem"
            v-if="viewRequest"
            @cancel="viewRequest = false"
        />
        <div v-if="!viewRequest">
            <breadcrumb
                name="Contact Us"
                title="Contact Us Requests"
                subtitle="List"
            />

            <div id="data-list-thumb-view" class="data-list-container">
                <vs-row style="width: 55%; margin-left: 1%">
                    <vs-col
                        vs-sm="4"
                        vs-lg="4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                    >
                        <vs-input
                            type="date"
                            v-model="from_date"
                            class="input"
                            size="medium"
                            label="From Date"
                        />
                    </vs-col>
                    <vs-col
                        vs-sm="4"
                        vs-lg="4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                    >
                        <vs-input
                            type="date"
                            v-model="to_date"
                            class="input ml-1"
                            size="medium"
                            label="To Date"
                        />
                    </vs-col>
                    <vs-col
                        vs-sm="4"
                        vs-lg="4"
                        vs-type="flex"
                        vs-align="center"
                    >
                        <vs-button
                            style="margin-top: 22px"
                            class="ml-1"
                            color="success"
                            type="filled"
                            @click="filter"
                        >
                            Filter
                        </vs-button>
                    </vs-col>
                    <vs-col>
                        <vs-tabs v-model="agent_status" :color="colorx">
                            <vs-tab
                                label="All"
                                value="all"
                                @click="colorx = 'rgb(52.9, 80.8, 92.2)'"
                            >
                                <div></div>
                            </vs-tab>
                            <vs-tab
                                label="Pending"
                                value="pending"
                                @click="colorx = 'warning'"
                            >
                                <div></div>
                            </vs-tab>
                            <vs-tab
                                label="In Progress"
                                value="in_progress"
                                @click="colorx = 'primary'"
                            >
                                <div></div>
                            </vs-tab>
                            <vs-tab
                                label="Done"
                                value="done"
                                @click="colorx = 'success'"
                            >
                                <div></div>
                            </vs-tab>
                        </vs-tabs>
                    </vs-col>
                </vs-row>
                <vs-table
                    ref="table"
                    pagination
                    :max-items="itemsPerPage"
                    search
                    :data="products"
                >
                    <div
                        slot="header"
                        class="flex flex-wrap-reverse items-center flex-grow justify-between"
                    >
                        <div class="flex flex-wrap-reverse items-center">
                            <vs-button
                                color="primary"
                                type="filled"
                                @click="exportData"
                                >Export Requests</vs-button
                            >
                        </div>

                        <!-- ITEMS PER PAGE -->
                        <table-header
                            :current-page="currentPage"
                            :items-per-page="itemsPerPage"
                            :length="products.length"
                            :queried-items="queriedItems"
                            @onChangeItemsPerPage="itemsPerPage = $event"
                        />
                    </div>

                    <template slot="thead">
                        <vs-th sort-key="id">ID</vs-th>
                        <vs-th sort-key="date">Date</vs-th>
                        <vs-th sort-key="time">Time</vs-th>
                        <vs-th sort-key="name">Name</vs-th>
                        <vs-th sort-key="mobile">Mobile</vs-th>
                        <vs-th sort-key="email">Email</vs-th>
                        <vs-th sort-key="brand">Brand</vs-th>
                        <vs-th sort-key="status">Status</vs-th>

                        <vs-th sort-key="inquiry_type">Inquiry Type</vs-th>
                        <vs-th sort-key="status">Agent</vs-th>

                        <vs-th sort-key="elapsed_time">Elapsed Time</vs-th>
                        <vs-th>Action</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <tbody>
                            <vs-tr
                                :data="tr"
                                :key="indextr"
                                v-for="(tr, indextr) in data"
                            >
                                <string-row :data="tr.id" />
                                <string-row
                                    :data="
                                        tr.created_at | moment('DD MMMM YYYY')
                                    "
                                />
                                <string-row
                                    :data="tr.created_at | moment('h:m A')"
                                />
                                <string-row :data="tr.name" />
                                <string-row :data="tr.mobile" />
                                <string-row :data="tr.email" />
                                <string-row :data="tr.brand" />
                                <AgentStatusRow
                                    v-if="tr.agent_status"
                                    :data="
                                        tr.agent_status
                                            .toUpperCase()
                                            .replace('_', ' ')
                                    "
                                />
                                <vs-td v-else>None</vs-td>
                                <string-row :data="tr.inquiry_type" />
                                <string-row
                                    :data="tr.agent ? tr.agent.name : 'None'"
                                />
                                <string-row :data="tr.elapsed_time" />

                                <actions-row
                                    :show="true"
                                    permissionName="delete-contact-us-requests"
                                    @onShowClicked="viewItem(tr)"
                                    :editable="false"
                                    @onDeleteClicked="deleteItem(tr.id)"
                                />
                            </vs-tr>
                        </tbody>
                    </template>
                </vs-table>
            </div>
        </div>
    </div>
</template>

<script>
import { ChevronsRightIcon, EyeIcon, HomeIcon } from "vue-feather-icons";
import ViewRequest from "./view-contact-us-request";
import Breadcrumb from "../../../components/general/breadcrumb";
import TableHeader from "../../../components/table/table-header";
import StringRow from "../../../components/table/rows/string-row";
import ActionsRow from "../../../components/table/rows/actions-row";
import AgentStatusRow from "../../../components/table/rows/agent-status-row";

import io from "socket.io-client";
const socket = io(process.env.VUE_APP_SOCKET_URL);

export default {
    name: "contact-us",
    components: {
        ActionsRow,
        AgentStatusRow,
        StringRow,
        TableHeader,
        Breadcrumb,
        HomeIcon,
        ChevronsRightIcon,
        EyeIcon,
        ViewRequest,
    },
    data() {
        return {
            request: {
                id: null,
                name: "",
                email: "",
                comment: "",
                inquiry_type: "",
            },
            requests: [],
            requestItem: {},
            selected: [],
            mainData: {},
            itemsPerPage: 20,
            isMounted: false,
            addNewDataSidebar: false,
            sidebarData: {},
            viewRequest: false,
            from_date: "",
            to_date: "",
            colorx: "dark",
            agent_status: 0,
        };
    },
    computed: {
        currentPage() {
            if (this.isMounted) {
                return this.$refs.table.currentx;
            }
            return 0;
        },
        products() {
            return this.requests;
        },
        queriedItems() {
            return this.$refs.table
                ? this.$refs.table.queriedResults.length
                : this.products.length;
        },
    },
    watch: {
        agent_status() {
            this.getRequests();
        },
    },
    methods: {
        viewItem(item) {
            this.requestItem = item;
            this.viewRequest = true;
            console.log(item);
        },
        deleteItem(id) {
            this.$vs.loading();
            this.$httpAdmin
                .delete(`contact-us/${id}`)
                .then((r) => {
                    this.$vs.loading.close();
                    console.log(r);
                    this.$vs.notify({
                        title: "Success",
                        text: "Request is deleted successfully!",
                        color: "success",
                    });
                    this.getRequests();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Couldn't Load Data!",
                        color: "danger",
                    });
                });
        },
        exportData() {
            let queryString = "?r=a";

            if (this.from_date.length > 0) {
                queryString += `&from_date=${this.from_date}`;
            }
            if (this.to_date.length > 0) {
                queryString += `&to_date=${this.to_date}`;
            }
            if (this.agent_status > 0) {
                const statuses = {
                    1: "pending",
                    2: "in_progress",
                    3: "done",
                };
                queryString += `&agent_status=${statuses[this.agent_status]}`;
            }
            window.location.href =
                process.env.VUE_APP_ADMIN_URL +
                "/" +
                "contact-us/export" +
                queryString;
        },
        getRequests() {
            this.$vs.loading();
            let queryString = "contact-us?r=a";

            if (this.from_date.length > 0) {
                queryString += `&from_date=${this.from_date}`;
            }
            if (this.to_date.length > 0) {
                queryString += `&to_date=${this.to_date}`;
            }
            if (this.agent_status > 0) {
                const statuses = {
                    1: "pending",
                    2: "in_progress",
                    3: "done",
                };
                queryString += `&agent_status=${statuses[this.agent_status]}`;
            }
            this.$httpAdmin
                .get(queryString)
                .then((r) => {
                    this.$httpAdmin
                        .get(`${queryString}&per_page=${r.data.data.total}`)
                        .then((r) => {
                            this.requests = r.data.data.data;
                            //console.log(this.requests);
                            this.$vs.loading.close();
                        })
                        .catch(() => {
                            this.$vs.loading.close();
                            ///console.log(e);
                            this.$vs.notify({
                                title: "Error",
                                text: "Couldn't Load Data!",
                                color: "danger",
                            });
                        });
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Couldn't Load Data!",
                        color: "danger",
                    });
                });
        },
        openLink(link) {
            if (!/^http/.test(link)) {
                window.open("http://" + link, "_blank");
            } else {
                window.open(link, "_blank");
            }
        },
        getOrderStatusColor(status) {
            if (status == "cancelled" || status == "canceled") return "warning";
            if (status == "active") return "success";
            return "primary";
        },
        getOrderStatusState(status) {
            if (status == 0) return "Unavailable";
            if (status == 1) return "Available";
            return "Unknown";
        },
        filter() {
            this.getRequests();
        },
    },
    mounted() {
        this.isMounted = true;
        this.getRequests();

        socket.on("new-request", (data) => {
            if (data.type === "NEW_CONTACT_US") {
                this.getRequests();
            }
        });
    },
};
</script>

<style lang="scss">
@import "../../basic-styles/mixins";

%flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
}

.images {
    @include margin-y(30px);
    text-align: center;
}

.optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
        position: relative;
        left: 25px;
    }
}

.input {
    width: 100%;
    @include margin-y(30px);
}

.arabic,
.english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
}

h1 {
    margin-bottom: 25px;
}

.inputs {
    @extend %flex;
}

ul {
    li {
        display: inline;
    }
}

.clr {
    clear: both;
}

$primaryColor: #054f7c;

.breadcrumb {
    margin: 20px;
}

.back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
}

.main-component {
    color: $primaryColor;
}

.required {
    color: red;
    font-size: 1.5rem;
}

.mandatory {
    color: red;
}

.arabic,
.english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
}

.body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px;
}

.custom-class {
    color: $primaryColor;
}

.title {
    font-size: 2rem;
    font-weight: 400;
}

.separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
}

.label {
    color: #000;
}

#data-list-thumb-view {
    .vs-con-table {
        .product-name {
            max-width: 23rem;
        }

        .vs-table--header {
            display: flex;
            flex-wrap: wrap-reverse;
            margin-left: 1.5rem;
            margin-right: 1.5rem;

            > span {
                display: flex;
                flex-grow: 1;
            }

            .vs-table--search {
                padding-top: 0;

                .vs-table--search-input {
                    padding: 0.9rem 2.5rem;
                    font-size: 1rem;

                    & + i {
                        left: 1rem;
                    }

                    &:focus + i {
                        left: 1rem;
                    }
                }
            }
        }

        .vs-table {
            border-collapse: separate;
            border-spacing: 0 1.3rem;
            padding: 0 1rem;

            tr {
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

                td {
                    padding: 10px;

                    &:first-child {
                        border-top-left-radius: 0.5rem;
                        border-bottom-left-radius: 0.5rem;
                    }

                    &:last-child {
                        border-top-right-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
                    }

                    &.img-container {
                        // width: 1rem;
                        // background: #fff;

                        span {
                            display: flex;
                            justify-content: flex-start;
                        }

                        .product-img {
                            height: 110px;
                        }
                    }
                }

                td.td-check {
                    padding: 20px !important;
                    display: none !important;
                }
            }
        }

        .vs-table--thead {
            th {
                padding-top: 0;
                padding-bottom: 0;

                .vs-table-text {
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }

            th.td-check {
                padding: 0 15px !important;
                display: none !important;
            }

            tr {
                background: none;
                box-shadow: none;
            }
        }

        .vs-table--pagination {
            justify-content: center;
        }
    }
}
</style>
